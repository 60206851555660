import React,{useRef} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from "react-slick"
import PortfolioDetails from './style'
import pimageOne from "~image/metamorphosis/1.jpg"
import pimageTwo from "~image/metamorphosis/2.jpg"
import pimageThree from "~image/metamorphosis/3.jpg"
import pimageFoure from "~image/metamorphosis/4.jpg"
import pimageFive from "~image/metamorphosis/5.jpg"
import pimageSix from "~image/metamorphosis/6.jpg"
import pimageSeven from "~image/metamorphosis/7.jpg"
import pimageEight from "~image/metamorphosis/8.jpg"
import pimageNine from "~image/metamorphosis/9.jpg"
import pimageTen from "~image/metamorphosis/10.jpg"
import pimageEleven from "~image/metamorphosis/11.jpg"
import pimageTwelve from "~image/metamorphosis/12.jpg"
import pimageThirteen from "~image/metamorphosis/13.jpg"
import pimageFourteen from "~image/metamorphosis/14.jpg"
import pimageFifteen from "~image/metamorphosis/15.jpg"
import pimageSixteen from "~image/metamorphosis/16.jpg"
import pimageSeventeen from "~image/metamorphosis/17.jpg"
import pimageEighteen from "~image/metamorphosis/18.jpg"
import pimageNineteen from "~image/metamorphosis/19.jpg"
import pimageTwenty from "~image/metamorphosis/20.jpg"
import pimageTwentyOne from "~image/metamorphosis/21.jpg"
import pimageTwentyTwo from "~image/metamorphosis/22.jpg"
import pimageTwentyThree from "~image/metamorphosis/23.jpg"
import pimageTwentyFour from "~image/metamorphosis/24.jpg"
import pimageTwentyFive from "~image/metamorphosis/25.jpg"
import pimageTwentySix from "~image/metamorphosis/26.jpg"
import pimageTwentySeven from "~image/metamorphosis/27.jpg"
import pimageTwentyEight from "~image/metamorphosis/28.jpg"
import pimageTwentyNine from "~image/metamorphosis/29.jpg"
import pimageThirty from "~image/metamorphosis/30.jpg"
import pimageThirtyOne from "~image/metamorphosis/31.jpg"
import pimageThirtyTwo from "~image/metamorphosis/32.jpg"
import pimageThirtyThree from "~image/metamorphosis/33.jpg"
import pimageThirtyFour from "~image/metamorphosis/34.jpg"
import pimageThirtyFive from "~image/metamorphosis/35.jpg"
import pimageThirtySix from "~image/metamorphosis/36.jpg"
import pimageThirtySeven from "~image/metamorphosis/37.jpg"
import pimageThirtyEight from "~image/metamorphosis/38.jpg"
import pimageThirtyNine from "~image/metamorphosis/39.jpg"
import pimageForty from "~image/metamorphosis/40.jpg"


export default function PortfolioDetailsSection(){
  const elSlider = useRef()

  const sliderConfig1 = {
    infinite: true,
    speed:500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    draggable: true,
    dots: true,
    lazyLoad: 'ondemand',
    responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
  }
return(
<PortfolioDetails backgroundColor="#f3f4f6">
  <Container>
        <PortfolioDetails.Box>
          <Row className="align-items-center">
            <Col xs="12" className="col-xl-6 col-lg-6">
              <PortfolioDetails.Content>
                <PortfolioDetails.Title as="h3">Metamorphosis <br/> - A Photo Biography</PortfolioDetails.Title>
                <PortfolioDetails.Text>
                A pictorial journey of the Pioneering efforts of the <i>Mentors and Masterminds of Endocrine Surgery.</i> Published in 2012 to commemorate the <i>25th Anniversary</i> of the Department of Endocrine Surgery, Madras Medical College. 
                
                </PortfolioDetails.Text>
                


              </PortfolioDetails.Content>
            </Col>
            <Col xs="12" className="col-xl-4 offset-xl-2">
              <PortfolioDetails.Content>
              
            
              </PortfolioDetails.Content>
            </Col>
          </Row>
        </PortfolioDetails.Box>
        <PortfolioDetails.SliderWrapper mt="50px" mtLG="80px">
          <Slider 
          ref={elSlider}
          className="testimonial-slider"
          {...sliderConfig1}
          >
              
              <PortfolioDetails.Slide>
                <img src={pimageTwo}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageThree}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageFoure}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageFive}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageSix}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageSeven}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageEight}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageNine}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageTen}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageEleven}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageTwelve}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageThirteen}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageFourteen}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageFifteen}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageSixteen}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageSeventeen}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageEighteen}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageNineteen}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageTwenty}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageTwentyOne}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageTwentyTwo}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageTwentyThree}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageTwentyFour}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageTwentyFive}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageTwentySix}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageTwentySeven}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageTwentyEight}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageTwentyNine}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageThirty}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageThirtyOne}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageThirtyTwo}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageThirtyThree}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageThirtyFour}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageThirtyFive}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageThirtySix}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageThirtySeven}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageThirtyEight}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageThirtyNine}/>
              </PortfolioDetails.Slide>
              <PortfolioDetails.Slide>
                <img src={pimageForty}/>
              </PortfolioDetails.Slide>
              {/* <PortfolioDetails.Slide>
                <img src={pimageOne}/>
              </PortfolioDetails.Slide> */}
          </Slider>
          <h5 className="align-items-center"><br/><br/><a target="_blank" href = "https://drive.google.com/file/d/1DcvCgj4VrisWGcx4dH0Pjs3FrXSdP49E/view?usp=sharing">View Metamorphosis as a PDF</a></h5>
        </PortfolioDetails.SliderWrapper>
  </Container>
</PortfolioDetails>

)
}